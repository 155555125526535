.wrap{
    display: flex;
    align-items: center;
    justify-content:space-between;
    height: 100px;
    margin: 10px 0;
    margin-top: 20px;
    .logo{
        cursor: pointer;
      img{
        height: 68px;
      }
    }
    .searchWrap{
      .ant-input-group-addon:first-child{
        padding: 0;
      }
    }
    .ext{
        cursor: pointer;
        //border-radius: 4px;
        //border:1px solid #efc107;
        font-size: 15px;
        border:1px solid #ff9c45;
        color:#ff9c45;
        background: #fff;
        padding: 0 15px;
        line-height: 30px;
        font-weight: normal;
        .iconbianji{
            margin-right: 8px;
        }
    }
    .ext:hover{
        background: #ff9c45;
        color: #fff;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;