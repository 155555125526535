.top-header{
    background: #1c1c1c;
    .wraper{
        background:#1c1c1c;
        flex:1;
        display: flex;
        line-height: 40px;
        font-size: 12px;
        .welcome{
            flex:1;
            color: #fff;
            display: flex;
            >div{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .ext{
            display: flex;
            align-items: center;
            .userinfo{
              color: #fff;
              img{
                width: 20px;
                height: 20px;
                margin-right: 5px;
                border-radius: 50%;
              }
            }
            a{
                color:#fff;
                font-size: 12px;
            }
            i{
                font-size: 12px;
                font-style: normal;
                background:#fff;
                color:#fff;
                margin: 2px 6px 0;
                height: 14px;
                width: 1px;
            }
        }
    }
  .menu{
    position: relative;
    width: 88px;
    text-align: center;

    z-index: 1000;
    .menuBox{
      padding-bottom: 8px;
      display: none;
      position: absolute;
      top: 35px;
      left: -1px;
      background: #fff;
      border: 1px solid #eee;
      a{
        display: block;
        width: 86px;
        text-align: center;
        color: #666;
        line-height: 30px;
      }
    }

    &:hover{
      //width: 88px;
      background: #fff;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      .menuTitle{
        color: #666;
      }
      .menuBox{
        display: block;
        border-top:0 ;
        a{
          text-align: center;
          display: block;
          color: #666;
          line-height: 32px;
        }
        a:hover{
          color: #1EAA39 ;
          background: #efefef;
        }
      }


    }
  }
  .link:hover{
    text-decoration: underline;
  }
  :global{
    .ant-menu-item-active{
      color: #1EAA39 !important;
    }
  }
}
.items .item:last-of-type{
  border: 0;
}
.item{
  margin: 0 10px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px  dotted #D9D9D9;
}
.item:hover{
  color: #1EAA39 ;
  background: initial;
}

@primary-color: #0f8605;@border-radius-base: 2px;