.body{
    margin-bottom: 20px;
    display: flex;
    .sideBar{
        width: 280px;
        margin-right: 20px;
        background: #fff;
    }
    .mainBody{
        flex: 1;
        overflow: hidden;
    }
  .typeBox{
    display: flex;
    &>div{
      width: 93px;
    /*  line-height: 35px;*/
      font-size: 16px;
      color: #666;
      text-align: center;
      margin: 0 10px;
      cursor: pointer;
    }
    .active{
      background: #129333;
      font-weight: bold;
      color: #fff;
    }
  }

  .top{
    display: flex;
    border-bottom: 1px dashed #eee;
    font-size: 16px;
    text-align: center;
    .name{
      width: 200px;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .time{
      width: 140px;
      line-height: 50px;
    }
    .status{
      line-height: 50px;
      flex: 1;
    }
    .operation{
      line-height: 50px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn{
        line-height: 23px;
        font-size: 14px;
        color: #5AB2A2;
        border: 1px solid #5AB2A2;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 5px;
        &:hover{
          background: #5AB2A2;
          color: #fff;
        }
      }

      .delBtn{
        line-height: 27px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        border:1px solid #BEBEBE;
        color: #999999;
        padding: 0 5px;
        margin-left: 10px;
        &:hover{
          color: #fff;
          border-color: #5AB2A2;
          background: #5AB2A2;
        }
        &:last-child{
          margin-right: 0;
        }
      }

    }
  }
  .list{
    height: 600px;
    //overflow-y: scroll;
    //position: relative;
  }
  .header{
    border-bottom: 1px solid #eee;
    .name{
      font-weight: normal;
      text-align: center;
    }
  }
  .row {
      .other{
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          margin-bottom: 10px;
          div{
            width: 100%;
          }
      }
  }
  .pubBtn {
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    border-radius: 5px;
    color: #14AF97;
    background: #fff;
    font-size: 16px;
    padding: 0 8px;
    //border: 1px solid #14AF97;
    margin-right: 5px;

    &:hover {
      color: #fff;
      background: #14AF97;

    }
  }
  :global{
    .ant-btn-primary{
      background: #5AB2A2;
      border-color: #5AB2A2;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;