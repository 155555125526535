.upload{
  .uploadText{
    font-size: 12px;
    color: #999;
    //white-space: nowrap;
  }
}
.uploadImg{
  .upload_text{
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    margin-top: -5px;
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;