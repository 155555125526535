

.detail{

  text-align: center;
  margin:auto;
  :global{
    //.ant-descriptions-item-label{
    //  display: inline-block;
    //  width: 50%;
    //  text-align: right;
    //}
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;