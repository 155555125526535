.list {
  overflow: hidden;
  padding-bottom: 20px;
  .item {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    border-radius: 3px;
    overflow: hidden;
    background: #fff;
    padding: 15px 28px;
    margin-bottom: 18px;

    .itemInner {
      color: red;
    }

    .title {
      flex: 2;
      display: flex;
      justify-content: space-between;
      color: #333;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:first-child :hover {
        color: #ff9c45;
        text-decoration: underline;
      }
      .createTime {
        color: #666;
        font-size: 13px;
        font-weight: normal;
        display: inline-block;
        :hover{
          text-decoration: none;

        }
        //margin-right: 20px;
      }
    }

    .subTitle {
      font-size: 15px;
      color: #333;
      line-height: 22px;
      margin-top: 8px;
      button {
        color: #ff9c45
      }
      button:hover{
        color: #FF7700;
      }
    }

    .footer {
      text-align: right;
      color: #666;
      font-size: 13px;
      margin-top: -25px;
      margin-bottom: 10px;
      .createTime {
        color: #666;
        font-size: 13px;
        display: inline-block;
        //margin-right: 20px;
      }

      button {
        font-size: 13px;
        padding: 0;
        color: #666;
      }

      .active {
        color: #ec7602;
      }
    }
  }
}

/* @media screen and (min-width: 1440px) and (max-width: 1680px) {

} */
/*
@media screen and (min-width: 1260px) and (max-width: 1440px) {
  .list {
    .item {
      width: 33.33%;
    }
  }
}*/

@primary-color: #0f8605;@border-radius-base: 2px;