.commune{
  padding:20px 60px ;
  background: #fff;
  position: relative;
  min-height: 400px;
  .boxTitle{
    padding:12px 0 12px 14px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    color:#000;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &:before{
      position:absolute;
      top:50%;
      left: 0;
      content: '';
      width: 4px;
      height: 18px;
      background:#0f8605;
      margin-top: -8px;
    }
    .info{
      position: absolute;
      right: 0;
      font-size:14px;
      color: #666;
      font-weight: normal;
      .high{
        display: inline-block;
        margin-right: 30px;
        color: #333;
        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  .backBtn{
    position: absolute;
    right: 60px;
    top: 20px;
  }
  .list{
    line-height: 32px;
    font-size:18px;
    color: #333;
    .question{
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .title{
        font-weight:bold;
      }
      .subtitle{
        font-size: 16px;
        color:#999;
        span{
          display: inline-block;
          line-height: 16px;
          padding-right: 20px;
          border-right: 2px solid #ddd;
          margin-right: 20px;
        }
        span:last-of-type{
          border: 0;
        }
      }
      .replyBox{
          background: #F7F8FA;
          padding: 10px;
          border-radius: 5px;
      }
      .reply{
        font-size: 16px;
        color: #333;
      }
      .defaultReplyBtn{
        font-size: 16px;
        padding: 0 15px;
        float: right;
        cursor: pointer;
        color: #4D80CF;
      }
      .replyBtn{
        background: #F7F8FA;
      }

    }
  }
  .btns{
    display: flex;
    justify-content: space-around;
    margin-top: 34px;
    padding: 0 70px;
    button{
      width: 210px;
    }
  }

}
.quiz{
  position: fixed;
  bottom: 80px;
  right: 27px;
  z-index: 1000;
  img{
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}
.emptyBox{
  text-align: center;
  margin: 100px;
  .emptyText{
    color: #CCCCCC;
    font-size: 18px;
    margin: 5px 0;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;