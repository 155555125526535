.box {
  .item {
    font-size: 13px;
    cursor: pointer;

    .title {
      padding: 11px 0 11px 12px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        margin-top: -3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #999;
      }
    }

  }

}
@primary-color: #0f8605;@border-radius-base: 2px;