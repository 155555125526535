.accountHeaderWrap{
  padding: 10px 0;
  background: #009F8F;
  box-shadow: 0 0 3px #ddd;
}
.accountHeader{
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    align-items: center;
    .logo{
        cursor: pointer;
        img{
          width: 478px;
        }
    }
  .ext{
    display: flex;
    align-items: center;
    .goHome{
      color: #fff;
      margin-right: 30px;
      &:hover{
        text-decoration: underline;

      }
    }
    .userinfo{
      color: #fff;
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
      }
      span{
        vertical-align: middle;
      }
    }
    a{
      color:#fff;
      font-size: 14px;
    }
    i{
      font-size: 14px;
      font-style: normal;
      background:#fff;
      color:#fff;
      margin: 2px 6px 0;
      height: 14px;
      width: 1px;
    }
  }
  .menu{
    position: relative;
    width: 88px;
    text-align: center;
    .menuBox{
      display: none;
      position: absolute;
      left: -1px;
      padding-top: 5px;
      padding-bottom: 8px;
      background: #009F8F;
      z-index: 1000;
      a{
        display: block;
        width: 86px;
        text-align: center;
        color: #fff;
        line-height: 30px;

      }
    }

    &:hover{
      //width: 88px;
      background: #009F8F;
      .menuTitle{
        color: #fff;
      }
      a:hover{
        text-decoration: underline;
      }
      .menuBox{
        display: block;
        border-top:0 ;
        a{
          text-align: center;
          display: block;
          color: #fff;
          line-height: 32px;
        }

      }


    }
}
  .link:hover{
    text-decoration: underline;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;