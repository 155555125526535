.body{
  flex: 1;
  background: #fff;
  padding: 0 22px;
  min-height: calc(100vh - 370px);
  .content {
      padding: 20px;
      font-size: 14px;
      color: #666;
      text-align: justify;
    a{
      color: #1D8526;
      &:hover{
        text-decoration: underline;
      }
    }
    p{
      text-indent: 2em;
    }
  }
}


@primary-color: #0f8605;@border-radius-base: 2px;